<template>
  <v-container fluid pa-0>
    <div v-if="!isTicketDetails">
      <div v-if="text === 'ticket'">
        <div class="mt-4">
          <v-card
            elevation="0"
            width="100%"
            :class="$vuetify.breakpoint.smAndDown ? 'mb-12' : null"
          >
            <div v-if="tickets.length > 0 || filteredTable">
              <v-row class="mt-0" :no-gutters="this.$vuetify.breakpoint.xs">
                <!-- Ticket Table -->
                <v-col v-if="this.$vuetify.breakpoint.mdAndUp">
                  <v-data-table
                    id="main-tickets-table"
                    v-model="selected"
                    fixed-header
                    :headers="headers"
                    :options.sync="options"
                    :loading="loading"
                    :items="tickets"
                    item-key="ticket_id"
                    :height="`calc(100vh - 270px)`"
                    color="primary"
                    checkbox-color="primary"
                    :items-per-page="!$vuetify.breakpoint.mdAndUp ? -1 : 15"
                    :class="$vuetify.breakpoint.mdAndUp ? 'ml-2' : null"
                    :hide-default-footer="!$vuetify.breakpoint.mdAndUp"
                    :loading-text="$t('loading')"
                    :footer-props="{
                      'items-per-page-options': perPageChoices,
                    }"
                  >
                    <template
                      v-if="$vuetify.breakpoint.mdAndUp"
                      v-slot:[`body.prepend`]
                    >
                      <tr>
                        <!-- Select All -->
                        <!-- Hide this toolbar checkbox until feature is complete, remember to add show select above -->
                        <!-- <td></td> -->
                        <!-- Excavation Date -->
                        <td>
                          <v-text-field
                            v-model="filters.start_date"
                            type="Date"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('start_date')"
                          ></v-text-field>
                        </td>
                        <!-- TicketRevision -->
                        <td>
                          <v-text-field
                            v-model="filters.center_ticket_id"
                            type="text"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('center_ticket_id')"
                          ></v-text-field>
                        </td>
                        <!-- SiteContactName -->
                        <td>
                          <v-text-field
                            v-model="filters.contact"
                            type="text"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('contact')"
                          ></v-text-field>
                        </td>
                        <!-- SiteContactPhone -->
                        <td>
                          <v-text-field
                            v-model="filters.contact_phone"
                            type="text"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('contact_phone')"
                          ></v-text-field>
                        </td>
                        <!-- WorkDoneFor -->
                        <td>
                          <v-text-field
                            v-model="filters.done_for"
                            type="text"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('done_for')"
                          ></v-text-field>
                        </td>
                        <!-- Priority -->
                        <td>
                          <v-text-field
                            v-model="filters.priority"
                            type="text"
                            class="upperCase"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('priority')"
                          ></v-text-field>
                        </td>
                        <!-- Ticket Type -->
                        <td>
                          <v-text-field
                            v-model="filters.type"
                            type="text"
                            class="upperCase"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('type')"
                          ></v-text-field>
                        </td>
                        <!-- Status -->
                        <td>
                          <!-- <v-text-field v-model="filters.status" type="text" clear-icon="mdi-close-circle" clearable></v-text-field> -->
                        </td>
                        <!-- Expiration Date -->
                        <td>
                          <v-text-field
                            v-model="filters.expiration_date"
                            type="Date"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('expiration_date')"
                          ></v-text-field>
                        </td>
                        <!-- User -->
                        <td>
                          <v-select
                            v-model="selectUserId"
                            :items="users"
                            item-text="name"
                            item-value="user_id"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="selectClear('user_id')"
                          ></v-select>
                        </td>
                        <!-- Center -->
                        <td>
                          <v-select
                            v-model="selectCenterId"
                            :items="centers"
                            item-text="center_name"
                            item-value="center_id"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="selectClear('center_id')"
                          ></v-select>
                        </td>
                        <!-- Address -->
                        <td>
                          <v-text-field
                            v-model="filters.street_address"
                            type="text"
                            class="upperCase"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('street_address')"
                          ></v-text-field>
                        </td>
                        <!-- Place -->
                        <td>
                          <v-text-field
                            v-model="filters.place"
                            type="text"
                            class="upperCase"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('place')"
                          ></v-text-field>
                        </td>
                        <!-- County -->
                        <td>
                          <v-text-field
                            v-model="filters.county"
                            type="text"
                            class="upperCase"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('county')"
                          ></v-text-field>
                        </td>
                        <!-- State -->
                        <td>
                          <v-text-field
                            v-model="filters.state"
                            type="text"
                            class="upperCase"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('state')"
                          ></v-text-field>
                        </td>
                      </tr>
                    </template>
                    <template v-slot:[`item.contact`]="{ item }">
                      <div class="nowrap">
                        {{ handleEmptyData(item.contact) }}
                      </div>
                    </template>
                    <template v-slot:[`item.contact_phone`]="{ item }">
                      <div class="nowrap">
                        {{ handleEmptyData(item.contact_phone) }}
                      </div>
                    </template>
                    <template v-slot:[`item.done_for`]="{ item }">
                      <div class="nowrap">
                        {{ handleEmptyData(item.done_for) }}
                      </div>
                    </template>
                    <template v-slot:[`item.start_date`]="{ item }">
                      <div class="nowrap">
                        {{ formatTicketDate(item.start_date) }}
                      </div>
                    </template>
                    <template v-slot:[`item.fullAddress`]="{ item }">
                      <div class="nowrap">{{ item.fullAddress }}</div>
                    </template>
                    <template v-slot:[`item.center_ticket_id`]="{ item }">
                      <a
                        class="nowrap primary--text"
                        @click="onTicketDetails(item)"
                      >
                        {{ item.center_ticket_id }} - {{ item.revision }}
                      </a>
                      <!-- </router-link> -->
                    </template>
                    <template v-slot:[`item.priority`]="{ item }">
                      <div class="nowrap ma-2">
                        <v-icon
                          x-small
                          :color="priorityIconColor(item.priority)"
                        >
                          {{ priorityIcon(item.priority) }}
                        </v-icon>
                        {{ item.priority }}
                      </div>
                    </template>
                    <template v-slot:[`item.ticket_type`]="{ item }">
                      {{ item.ticket_type }}
                    </template>
                    <template v-slot:[`item.state`]="{ item }">
                      <div class="nowrap">{{ item.state }}</div>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <div
                        v-if="
                          hasMembersToShow(item.receipt) &&
                          !(
                            item.type === 'CNCL' ||
                            item.type === 'RXMT' ||
                            item.type === '3HRS' ||
                            item.type === 'DMAR' ||
                            item.type === 'REPT' ||
                            item.type === '2NDR' ||
                            (item.type === 'UPDT' &&
                              getCenterName(item.center_id) === 'JULIE') ||
                            item.priority === 'EMER'
                          )
                        "
                      >
                        <div v-if="supportsResponses(item.center_id)">
                          <v-btn
                            text
                            small
                            color="primary"
                            @click="
                              $vuetify.breakpoint.mdAndUp
                                ? showStatusPanel(item)
                                : showStatusDialog(item)
                            "
                          >
                            <v-icon
                              left
                              x-small
                              :color="
                                statusIconColor(item.receipt, item.responses)
                              "
                            >
                              mdi-circle
                            </v-icon>
                            {{ responseStatus(item.receipt, item.responses) }}
                          </v-btn>
                        </div>
                        <div v-else>
                          <v-btn
                            text
                            small
                            color="primary"
                            @click="
                              $vuetify.breakpoint.mdAndUp
                                ? showStatusPanel(item)
                                : showStatusDialog(item)
                            "
                          >
                            View Members
                          </v-btn>
                        </div>
                      </div>
                      <div v-if="item.type === 'CNCL'">
                        <v-btn text small color="primary">
                          <v-icon left x-small color="red"> mdi-circle </v-icon>
                          {{ $t("cancelled") }}
                        </v-btn>
                      </div>
                      <div v-if="item.type === '3HRS'">
                        <v-btn text small color="primary">
                          <v-icon left x-small color="red"> mdi-circle </v-icon>
                          {{ $t("threeHrNotice") }}
                        </v-btn>
                      </div>
                      <div v-if="item.type === 'RXMT'">
                        <v-btn text small color="primary">
                          <v-icon left x-small color="red"> mdi-circle </v-icon>
                          {{ $t("retransmitted") }}
                        </v-btn>
                      </div>
                      <div v-if="item.type === 'REPT'">
                        <v-btn text small color="primary">
                          <v-icon left x-small color="red"> mdi-circle </v-icon>
                          {{ $t("repeated") }}
                        </v-btn>
                      </div>
                      <div v-if="item.priority === 'EMER'">
                        <v-btn text small color="primary">
                          <v-icon left x-small color="red"> mdi-circle </v-icon>
                          {{ $t("digin") }}
                        </v-btn>
                      </div>
                      <div v-if="item.type === 'DMAR'">
                        <v-btn text small color="primary">
                          <v-icon left x-small color="red"> mdi-circle </v-icon>
                          {{ $t("Destroyed") }}
                        </v-btn>
                      </div>
                      <div
                        v-if="
                          item.type === 'UPDT' &&
                          getCenterName(item.center_id) === 'JULIE'
                        "
                      >
                        <v-btn text small color="primary">
                          <v-icon left x-small color="red"> mdi-circle </v-icon>
                          {{ $t("extended") }}
                        </v-btn>
                      </div>
                      <div v-if="item.type === '2NDR'">
                        <v-btn text small color="primary">
                          <v-icon left x-small color="red"> mdi-circle </v-icon>
                          {{ $t("secondNotice") }}
                        </v-btn>
                      </div>
                    </template>
                    <template v-slot:[`item.expiration_date`]="{ item }">
                      <div class="nowrap">
                        {{
                          item.center_name === "OHIO811"
                            ? "N/A"
                            : formatTicketDate(item.expiration_date)
                        }}
                      </div>
                    </template>
                    <template v-slot:[`item.street_address`]="{ item }">
                      <div class="nowrap">{{ item.street_address }}</div>
                    </template>
                    <template v-slot:[`item.user_id`]="{ item }">
                      <div class="nowrap">{{ getUserName(item.user_id) }}</div>
                    </template>
                    <template v-slot:[`item.place`]="{ item }">
                      <div class="nowrap">{{ item.place }}</div>
                    </template>
                    <template v-slot:[`item.county`]="{ item }">
                      <div class="nowrap">{{ item.county }}</div>
                    </template>
                    <template v-slot:[`item.center_id`]="{ item }">
                      <div class="nowrap">
                        {{ getCenterName(item.center_id) }}
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col v-if="this.$vuetify.breakpoint.smAndDown" class="pb-14">
                  <v-data-iterator
                    id="ticket-cards"
                    :items="tickets"
                    item-key="ticket_id"
                    fixed-header
                    show-select
                    loading-text="Loading items..."
                    no-data-text=""
                    :page.sync="options.page"
                    :loading="loading"
                    :options.sync="options"
                    :items-per-page="options.itemsPerPage"
                    disable-pagination
                    hide-default-footer
                    @toggle-select-all="mobileSelectAll"
                  >
                    <template v-slot:header>
                      <TicketsMobileDataToolbar
                        :display-toolbar="!!tickets.length"
                        :selected-tickets="selected"
                      >
                        <template v-slot:selectCheckbox>
                          <v-checkbox v-model="mobileSelectAll" />
                        </template>
                      </TicketsMobileDataToolbar>
                    </template>
                    <template v-slot:default="props">
                      <TicketsMobileCard
                        class="px-4"
                        v-for="item in props.items"
                        :key="item.ticket_id"
                        :item="item"
                        :is-selected="
                          !!selected.find(
                            (ticket) => ticket.ticket_id === item.ticket_id
                          )
                        "
                        :supports-responses="supportsResponses(item.center_id)"
                        @showStatusDialog="showStatusDialog"
                        @onTicketDetails="onTicketDetails"
                      >
                        <template v-slot:selectCheckbox>
                          <v-checkbox
                            v-model="selected"
                            :value="item"
                            :multiple="true"
                            :value-comparator="setMobileCheckBoxState"
                          />
                        </template>
                      </TicketsMobileCard>
                    </template>
                  </v-data-iterator>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
      </div>
      <v-bottom-navigation
        v-if="$vuetify.breakpoint.smAndDown"
        fixed
        horizontal
        height="80"
        class="px-2 py-4"
      >
        <v-row justify="space-between" no-gutters>
          <v-btn
            width="50%"
            depressed
            v-if="checkPermission('associateTicket')"
            color="primary"
            class="mr-2 text-none font-weight-regular grey--text rounded-lg elevation-0"
            @click="$emit('openAssociateTicketDialog')"
            ><span class="white--text text-none text-subtitle-1">{{
              $t("associateTicket")
            }}</span>
          </v-btn>
          <v-btn
            width="50%"
            depressed
            v-if="checkPermission('createTickets')"
            color="primary"
            class="text-none font-weight-regular grey--text rounded-lg elevation-0"
            @click="createTicket"
            ><span class="white--text text-none text-subtitle-1"
              ><v-icon>mdi-plus</v-icon>{{ $t("createTicket") }}</span
            >
          </v-btn>
        </v-row>
      </v-bottom-navigation>
    </div>

    <v-layout row justify-center v-if="responsesDialog">
      <v-dialog v-model="responsesDialog" fullscreen hide-overlay>
        <MembersPanel
          :ticket="selectedTicket"
          :show-responses="showResponses"
          @hideTicketResponses="hideTicketResponses"
        >
        </MembersPanel>
      </v-dialog>
    </v-layout>
    <v-layout row justify-end v-if="responsesPanel">
      <div id="responsePanel">
        <MembersPanel
          :ticket="selectedTicket"
          :show-responses="showResponses"
          @hideTicketResponses="hideTicketResponses"
        >
        </MembersPanel>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import { formatTicketDateTime } from "@/store/utils/utils.js";
import api from "../../../store/api";
import UserDataMixin from "@/mixins/UserData";
import MemberMixin from "@/mixins/MemberMixin";
import { bus } from "@/main";
export default {
  name: "TicketMain",
  mixins: [UserDataMixin, MemberMixin],
  components: {
    TicketsMobileCard: () => import("./TicketsMobileCard"),
    TicketsMobileDataToolbar: () => import("./TicketsMobileDataToolbar"),
    MembersPanel: () => import("@/components/members/MembersPanel"),
  },
  props: {
    projectDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      createTicketButton: false,
      ticketSuccessAlert: false,
      ticketAlertMessage: "",
      bottomNavbar: true,
      selected: [],
      tickets: [],
      totalItems: 0,
      loading: false,
      showTicketResponses: false,
      responsesDialog: false,
      responsesPanel: false,
      isTicketDetails: false,
      ticketRowData: {},
      options: {
        sortDesc: [true],
        sortBy: ["created_at"],
        page: 1,
        itemsPerPage: 15,
      },
      filters: {
        start_date: "",
        center_ticket_id: "",
        priority: "",
        type: "",
        expiration_date: "",
        status: "",
        center_id: "",
        street_address: "",
        place: "",
        county: "",
        state: "",
        user_id: "",
        contact: "",
        contact_phone: "",
        done_for: "",
      },
      text: "ticket",
      filteredTable: false,
      selectUserId: { name: "", user_id: "" },
      selectCenterId: { center_name: "", center_id: "" },
      headers: [
        { text: this.$t("startDate"), value: "start_date" },
        {
          text: this.$t("ticket"),
          align: "start",
          value: "center_ticket_id",
        },
        { text: "Site Contact Name", align: "start", value: "contact" },
        { text: "Site Contact Phone", align: "start", value: "contact_phone" },
        { text: "Work done for", align: "start", value: "done_for" },
        { text: this.$t("priority"), align: "start", value: "priority" },
        { text: this.$t("type"), value: "type" },
        { text: this.$t("responseStatus"), value: "status", sortable: false },
        { text: this.$t("expirationDate"), value: "expiration_date" },
        { text: this.$t("user"), value: "user_id" },
        { text: this.$t("811Center"), value: "center_id" },
        { text: this.$t("address"), value: "street_address" },
        { text: this.$t("place"), value: "place" },
        { text: this.$t("county"), value: "county" },
        { text: this.$t("state"), value: "state" },
      ],
      centers: [],
      users: [],
      selectedTicket: {},
      showResponses: false,
      dynamicCol: 12,
      responsesCol: 0,
      perPageChoices: [5, 10, 15, 20],
    };
  },
  computed: {
    subscriptionPlan() {
      return (
        this.$store.state.Company.company.subscription_id ===
        "00000000-0000-0000-0000-000000000000"
      );
    },
    tabs() {
      const allTabs = [
        { name: "My Tickets", permission: "viewTickets" },
        {
          name: "Awaiting Approval",
          permission: "viewTickets",
          checkApprover: true,
        },
        { name: "Approval Queue", permission: "approveTickets" },
        {
          name: "Scheduled Tickets",
          permission: "scheduleTickets",
          checkApprover: true,
        },
      ];
      //Does the company have any centers that have suspense tickets enabled?
      const additionalCenters =
        this.$store.state.Company.company.additionalCenters || [];
      const allCenters = [
        ...this.$store.state.Company.company.centers,
        ...additionalCenters,
      ];
      const companyCenters = this.$store.state.Company.company.centers;
      if (allCenters.length) {
        let isSuspendTabEnabled = false;
        companyCenters.forEach((center) => {
          // find the center in the user's centers
          const userCenter = allCenters.find(
            (c) => c.center_id === center.center_id
          );
          // if the user has access to the center and the center has suspend tickets enabled
          if (
            userCenter.options.newtin2?.suspend_converts?.length > 0 &&
            !isSuspendTabEnabled
          ) {
            allTabs.push({
              name: "Suspend Tickets",
              permission: "viewTickets",
            });
            isSuspendTabEnabled = true;
          }
        });
      }
      const activeTabs = [];
      const persona = this.$store.state.User.user.persona;
      const options = this.$store.state.Company.company.options;
      if (options) {
        const rolesAndPermissions = options.rolesAndPermissions;
        allTabs.forEach((tab) => {
          if (rolesAndPermissions[persona].includes(tab.permission)) {
            if (tab.checkApprover) {
              if (
                !this.$store.state.User.user.approver_id &&
                tab.permission !== "scheduleTickets"
              ) {
                return;
              } else if (
                this.$store.state.User.user.approver_id &&
                tab.permission === "scheduleTickets"
              )
                return;
            }
            activeTabs.push(tab.name);
          }
        });
      }

      return activeTabs;
    },
    mobileSelectAll: {
      set(newValue) {
        this.selected = newValue ? [...this.tickets] : [];
      },
      get() {
        const ticketCount = this.tickets.length;
        return ticketCount > 0 && ticketCount === this.selected.length;
      },
    },
  },
  watch: {
    ticketSuccessAlert(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.ticketSuccessAlert = false;
        }, 8000);
      }
    },
    options: {
      handler(newVal, oldVal) {
        if (
          newVal.itemsPerPage === oldVal.itemsPerPage &&
          newVal.page === oldVal.page &&
          newVal.sortBy[0] === oldVal.sortBy[0] &&
          newVal.sortDesc[0] === oldVal.sortDesc[0] &&
          !this.ticketSuccessAlert
        ) {
          return;
        }
        this.timeOut && clearTimeout(this.timeOut);
        this.timeOut = setTimeout(() => {
          this.getTickets(this.filters);
        }, 200);
      },
      deep: true,
    },
    tabs() {
      if (this.tabs.length === 0 && this.checkPermission("createTickets"))
        this.createTicketButton = true;
      else this.createTicketButton = false;
    },
  },
  created() {
    this.getTicketData();
    this.$store.commit("setIsEdit", "");
    bus.$on("RefreshTicketTable", this.getTickets);
    if (this.tabs.length === 0) this.createTicketButton = true;
    else this.createTicketButton = false;
  },
  mounted() {
    this.tickets =
      this.$store.state.Project.projectTickets[
        this.projectDetails.project_id
      ] || [];
    this.getCenters();
    this.getUsers();
    this.getTickets(this.filters);
  },
  methods: {
    hideTicketDetails() {
      this.isTicketDetails = false;
    },
    closeTicketAlert() {
      this.ticketSuccessAlert = false;
    },
    onTicketDetails(item) {
      this.$emit("onClickTicket", item);
    },
    checkPlan() {
      if (this.subscriptionPlan) {
        this.createTicketButton = true;
        return false;
      } else return true;
    },
    createTicket() {
      this.$router.push("/createTicket").catch();
    },
    async cancelTicket() {
      this.createTicketButton = false;
      await this.$store.commit("setCurrentStep", 1);
      if (this.tabs.length === 0 && this.subscriptionPlan)
        this.createTicketButton = true;
    },
    formatTicketDate(dateString) {
      return formatTicketDateTime(dateString);
    },
    async getTickets() {
      this.loading = true;
      const apiFilter = Object.assign({}, this.filters);
      apiFilter.project_id = this.projectDetails.project_id;
      let response = await api.ticket.getTickets(apiFilter);
      for (let ticket of response.tickets) {
        ticket.fullAddress = `${ticket.street_address} ${ticket.place} ${ticket.county} ${ticket.state}`;
        ticket.center_name = this.getCenterName(ticket.center_id);
      }
      this.$store.commit("setProjectTickets", {
        project_id: response?.tickets[0]?.project_id,
        tickets: response.tickets,
      });
      this.tickets = response.tickets.filter((ticket) => {
        return (
          !ticket.center_ticket_id.startsWith("S") &&
          ticket.project_id &&
          this.projectDetails &&
          this.projectDetails.project_id &&
          ticket.project_id == this.projectDetails.project_id
        );
      });

      this.loading = false;
    },
    async getTicketData() {
      const apiFilter = {};
      let response = await api.ticket.getTickets(apiFilter);
      for (let ticket of response.tickets) {
        ticket.fullAddress =
          ticket.street_address +
          " " +
          ticket.place +
          " " +
          ticket.county +
          " " +
          ticket.state;
        ticket.center_name = this.getCenterName(ticket.center_id);
      }
      this.$store.commit("setTickets", [...response.tickets]);
    },
    async getCenters() {
      let centers = this.$store.getters.centerMap;
      this.centers = Object.values(centers);
    },
    async getUsers() {
      this.users = this.$store.state.User.companyUsers;
    },
    responseStatus(receipt, responses) {
      let responseStatusText = "";
      let responded = 0;
      let total = 0;
      if (responses) {
        responded = responses.length;
      }
      total = this.totalMembersToShow(receipt.members);
      if (total !== 0) {
        responseStatusText = responded + "/" + total + " Received";
      }
      return responseStatusText;
    },
    supportsResponses(center_id) {
      if (this.centers.length === 0) {
        this.getCenters();
      }
      if (this.centers) {
        const _center = this.centers.find(
          (center) => center.center_id === center_id
        );
        if (_center) {
          const options = _center.options;
          if (options) {
            return options.newtin2.responses;
          }
        }
      }
      return false;
    },
    hasMembers(receipt) {
      if (receipt) {
        if (receipt.members) {
          return receipt.members.length > 0;
        }
      }
      return false;
    },
    priorityIconColor(priority) {
      switch (priority) {
        case "RUSH":
          return "#fdd835";
        case "EMER":
          return "red";
        case "NONC":
          return "light-blue";
        default:
          return "grey";
      }
    },
    priorityIcon(priority) {
      switch (priority) {
        case "RUSH":
          // yellow circle exclamation
          return "mdi-bell";
        case "EMER":
          // red bell exclamation
          return "mdi-bell";
        case "NONC":
          // light blue circle exclamation
          return "mdi-bell";
        default:
          return "mdi-circle";
      }
    },
    statusIconColor(receipt, responses) {
      let responded = 0;
      let total = 0;
      if (responses) {
        responded = responses.length;
      }
      if (receipt?.members) {
        for (let member of receipt.members) {
          if (member.show) {
            total++;
          }
        }
      }
      if (total !== 0 && responded === total) {
        return "green";
      } else {
        return "red";
      }
    },
    ticketRevisionClass(item) {
      return "status-open-ticketrevision";
    },
    async refreshTicketData() {
      this.createTicketButton = false;
      if (this.tabs.length === 0 && this.subscriptionPlan) {
        await this.$store.commit("setCurrentStep", 1);
        this.createTicketButton = true;
        return;
      }
      this.getTickets();
      this.filteredTable = true;
    },
    showStatusDialog(ticket) {
      this.showTicketResponses = true;
      this.responsesDialog = true;
      this.selectedTicket = ticket;
      this.showResponses = this.supportsResponses(ticket.center_id);
      this.responsesPanel = false;
    },
    showStatusPanel(ticket) {
      this.responsesDialog = false;
      this.showTicketResponses = true;
      this.responsesPanel = true;
      this.showResponses = this.supportsResponses(ticket.center_id);
      this.selectedTicket = ticket;
    },
    hideTicketResponses() {
      this.showTicketResponses = false;
      this.responsesDialog = false;
      this.responsesPanel = false;
    },
    ticketSearch() {
      this.filters.user_id =
        typeof this.selectUserId !== "object" ? this.selectUserId : "";
      this.filters.center_id =
        typeof this.selectCenterId !== "object" ? this.selectCenterId : "";

      // loop through filters and check for nulls
      for (let key in this.filters) {
        if (this.filters[key] === null) {
          this.filters[key] = "";
        }
        if (this.filters[key])
          this.filters[key] = this.filters[key].toUpperCase();
      }

      this.filteredTable = true;
      this.getTickets(this.filters);
    },
    getCenterName(centerId) {
      let center = this.centers.find((center) => center.center_id === centerId);
      return center ? center.center_name : "";
    },
    getUserName(userId) {
      if (this.users.length === 0) {
        this.getUsers();
      }
      let user = this.users.find((user) => user.user_id === userId);
      return user ? user.name : "";
    },
    getAddress(ticket) {
      let address = "";
      if (ticket?.address) {
        address = ticket.address;
        if (ticket.city) {
          address += ", " + ticket.city;
        }
        if (ticket.state) {
          address += ", " + ticket.state;
        }
        if (ticket.zip) {
          address += " " + ticket.zip;
        }
      }
      return address;
    },
    setMobileCheckBoxState(model, item) {
      return model?.ticket_id === item?.ticket_id;
    },
    selectClear(value) {
      if (value === "user_id") {
        this.selectUserId = { name: "", user_id: "" };
        this.filters.user_id = "";
      }

      if (value === "center_id") {
        this.selectUserId = { center_name: "", center_id: "" };
        this.filters.center_id = "";
      }

      this.ticketSearch();
    },
    filterChange(value) {
      this.filters[value] = "";
      this.ticketSearch();
    },
    hasMembersToShow(receipt) {
      if (receipt) {
        if (receipt.members) {
          return this.totalMembersToShow(receipt.members) > 0;
        }
      }
      return false;
    },
    handleEmptyData(value) {
      return value.trim() !== "" ? value : "Info Not on Ticket";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../../../scss/settings.scss";

.appbar-header.white {
  border: 1px solid $grey-lighten-2 !important;
}

.upperCase input {
  text-transform: uppercase;
}

.status-open-ticketrevision {
  text-decoration: none;
  /* color: #1976D2; */
}

.nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ticket-status {
  cursor: pointer;
  white-space: nowrap;
}

.empty-state {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#responsePanel {
  position: absolute;
  top: 0;
  right: 0;
  width: 460px;
  height: calc(100vh);
  transform-origin: center center;
  max-width: 460px;
  margin-right: 0px;
  margin-top: -60px;
  margin-left: 0px;
  margin-bottom: -20px;
  z-index: 100;
  background-color: #fff;
  border-left: 1px solid #e0e0e0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.3s ease;
}
.associate-ticket-button {
  border: 1px solid #1976d2;
  border-radius: 8px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  color: #1976d2;
}
</style>
