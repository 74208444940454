var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      !_vm.isTicketDetails
        ? _c(
            "div",
            [
              _vm.text === "ticket"
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "mt-4" },
                      [
                        _c(
                          "v-card",
                          {
                            class: _vm.$vuetify.breakpoint.smAndDown
                              ? "mb-12"
                              : null,
                            attrs: { elevation: "0", width: "100%" },
                          },
                          [
                            _vm.tickets.length > 0 || _vm.filteredTable
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "mt-0",
                                        attrs: {
                                          "no-gutters":
                                            this.$vuetify.breakpoint.xs,
                                        },
                                      },
                                      [
                                        this.$vuetify.breakpoint.mdAndUp
                                          ? _c(
                                              "v-col",
                                              [
                                                _c("v-data-table", {
                                                  class: _vm.$vuetify.breakpoint
                                                    .mdAndUp
                                                    ? "ml-2"
                                                    : null,
                                                  attrs: {
                                                    id: "main-tickets-table",
                                                    "fixed-header": "",
                                                    headers: _vm.headers,
                                                    options: _vm.options,
                                                    loading: _vm.loading,
                                                    items: _vm.tickets,
                                                    "item-key": "ticket_id",
                                                    height:
                                                      "calc(100vh - 270px)",
                                                    color: "primary",
                                                    "checkbox-color": "primary",
                                                    "items-per-page": !_vm
                                                      .$vuetify.breakpoint
                                                      .mdAndUp
                                                      ? -1
                                                      : 15,
                                                    "hide-default-footer":
                                                      !_vm.$vuetify.breakpoint
                                                        .mdAndUp,
                                                    "loading-text":
                                                      _vm.$t("loading"),
                                                    "footer-props": {
                                                      "items-per-page-options":
                                                        _vm.perPageChoices,
                                                    },
                                                  },
                                                  on: {
                                                    "update:options": function (
                                                      $event
                                                    ) {
                                                      _vm.options = $event
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      _vm.$vuetify.breakpoint
                                                        .mdAndUp
                                                        ? {
                                                            key: "body.prepend",
                                                            fn: function () {
                                                              return [
                                                                _c("tr", [
                                                                  _c(
                                                                    "td",
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "Date",
                                                                              "clear-icon":
                                                                                "mdi-close-circle",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.ticketSearch,
                                                                            "click:clear":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.filterChange(
                                                                                  "start_date"
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .filters
                                                                                  .start_date,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.filters,
                                                                                    "start_date",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "filters.start_date",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                              "clear-icon":
                                                                                "mdi-close-circle",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.ticketSearch,
                                                                            "click:clear":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.filterChange(
                                                                                  "center_ticket_id"
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .filters
                                                                                  .center_ticket_id,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.filters,
                                                                                    "center_ticket_id",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "filters.center_ticket_id",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                              "clear-icon":
                                                                                "mdi-close-circle",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.ticketSearch,
                                                                            "click:clear":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.filterChange(
                                                                                  "contact"
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .filters
                                                                                  .contact,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.filters,
                                                                                    "contact",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "filters.contact",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                              "clear-icon":
                                                                                "mdi-close-circle",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.ticketSearch,
                                                                            "click:clear":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.filterChange(
                                                                                  "contact_phone"
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .filters
                                                                                  .contact_phone,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.filters,
                                                                                    "contact_phone",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "filters.contact_phone",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                              "clear-icon":
                                                                                "mdi-close-circle",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.ticketSearch,
                                                                            "click:clear":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.filterChange(
                                                                                  "done_for"
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .filters
                                                                                  .done_for,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.filters,
                                                                                    "done_for",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "filters.done_for",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "upperCase",
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                              "clear-icon":
                                                                                "mdi-close-circle",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.ticketSearch,
                                                                            "click:clear":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.filterChange(
                                                                                  "priority"
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .filters
                                                                                  .priority,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.filters,
                                                                                    "priority",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "filters.priority",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "upperCase",
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                              "clear-icon":
                                                                                "mdi-close-circle",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.ticketSearch,
                                                                            "click:clear":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.filterChange(
                                                                                  "type"
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .filters
                                                                                  .type,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.filters,
                                                                                    "type",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "filters.type",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c("td"),
                                                                  _c(
                                                                    "td",
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "Date",
                                                                              "clear-icon":
                                                                                "mdi-close-circle",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.ticketSearch,
                                                                            "click:clear":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.filterChange(
                                                                                  "expiration_date"
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .filters
                                                                                  .expiration_date,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.filters,
                                                                                    "expiration_date",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "filters.expiration_date",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    [
                                                                      _c(
                                                                        "v-select",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              items:
                                                                                _vm.users,
                                                                              "item-text":
                                                                                "name",
                                                                              "item-value":
                                                                                "user_id",
                                                                              "clear-icon":
                                                                                "mdi-close-circle",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.ticketSearch,
                                                                            "click:clear":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.selectClear(
                                                                                  "user_id"
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm.selectUserId,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.selectUserId =
                                                                                    $$v
                                                                                },
                                                                              expression:
                                                                                "selectUserId",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    [
                                                                      _c(
                                                                        "v-select",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              items:
                                                                                _vm.centers,
                                                                              "item-text":
                                                                                "center_name",
                                                                              "item-value":
                                                                                "center_id",
                                                                              "clear-icon":
                                                                                "mdi-close-circle",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.ticketSearch,
                                                                            "click:clear":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.selectClear(
                                                                                  "center_id"
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm.selectCenterId,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.selectCenterId =
                                                                                    $$v
                                                                                },
                                                                              expression:
                                                                                "selectCenterId",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "upperCase",
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                              "clear-icon":
                                                                                "mdi-close-circle",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.ticketSearch,
                                                                            "click:clear":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.filterChange(
                                                                                  "street_address"
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .filters
                                                                                  .street_address,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.filters,
                                                                                    "street_address",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "filters.street_address",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "upperCase",
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                              "clear-icon":
                                                                                "mdi-close-circle",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.ticketSearch,
                                                                            "click:clear":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.filterChange(
                                                                                  "place"
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .filters
                                                                                  .place,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.filters,
                                                                                    "place",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "filters.place",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "upperCase",
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                              "clear-icon":
                                                                                "mdi-close-circle",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.ticketSearch,
                                                                            "click:clear":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.filterChange(
                                                                                  "county"
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .filters
                                                                                  .county,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.filters,
                                                                                    "county",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "filters.county",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "upperCase",
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                              "clear-icon":
                                                                                "mdi-close-circle",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.ticketSearch,
                                                                            "click:clear":
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.filterChange(
                                                                                  "state"
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .filters
                                                                                  .state,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.filters,
                                                                                    "state",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "filters.state",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          }
                                                        : null,
                                                      {
                                                        key: "item.contact",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "nowrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.handleEmptyData(
                                                                        item.contact
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "item.contact_phone",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "nowrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.handleEmptyData(
                                                                        item.contact_phone
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "item.done_for",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "nowrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.handleEmptyData(
                                                                        item.done_for
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "item.start_date",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "nowrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.formatTicketDate(
                                                                        item.start_date
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "item.fullAddress",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "nowrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.fullAddress
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "item.center_ticket_id",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "nowrap primary--text",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onTicketDetails(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.center_ticket_id
                                                                    ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      item.revision
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "item.priority",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "nowrap ma-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      "x-small":
                                                                        "",
                                                                      color:
                                                                        _vm.priorityIconColor(
                                                                          item.priority
                                                                        ),
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.priorityIcon(
                                                                            item.priority
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.priority
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "item.ticket_type",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.ticket_type
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "item.state",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "nowrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.state
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "item.status",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          return [
                                                            _vm.hasMembersToShow(
                                                              item.receipt
                                                            ) &&
                                                            !(
                                                              item.type ===
                                                                "CNCL" ||
                                                              item.type ===
                                                                "RXMT" ||
                                                              item.type ===
                                                                "3HRS" ||
                                                              item.type ===
                                                                "DMAR" ||
                                                              item.type ===
                                                                "REPT" ||
                                                              item.type ===
                                                                "2NDR" ||
                                                              (item.type ===
                                                                "UPDT" &&
                                                                _vm.getCenterName(
                                                                  item.center_id
                                                                ) ===
                                                                  "JULIE") ||
                                                              item.priority ===
                                                                "EMER"
                                                            )
                                                              ? _c("div", [
                                                                  _vm.supportsResponses(
                                                                    item.center_id
                                                                  )
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  text: "",
                                                                                  small:
                                                                                    "",
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    _vm
                                                                                      .$vuetify
                                                                                      .breakpoint
                                                                                      .mdAndUp
                                                                                      ? _vm.showStatusPanel(
                                                                                          item
                                                                                        )
                                                                                      : _vm.showStatusDialog(
                                                                                          item
                                                                                        )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      left: "",
                                                                                      "x-small":
                                                                                        "",
                                                                                      color:
                                                                                        _vm.statusIconColor(
                                                                                          item.receipt,
                                                                                          item.responses
                                                                                        ),
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " mdi-circle "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.responseStatus(
                                                                                      item.receipt,
                                                                                      item.responses
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  text: "",
                                                                                  small:
                                                                                    "",
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    _vm
                                                                                      .$vuetify
                                                                                      .breakpoint
                                                                                      .mdAndUp
                                                                                      ? _vm.showStatusPanel(
                                                                                          item
                                                                                        )
                                                                                      : _vm.showStatusDialog(
                                                                                          item
                                                                                        )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " View Members "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                ])
                                                              : _vm._e(),
                                                            item.type === "CNCL"
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                left: "",
                                                                                "x-small":
                                                                                  "",
                                                                                color:
                                                                                  "red",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-circle "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "cancelled"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            item.type === "3HRS"
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                left: "",
                                                                                "x-small":
                                                                                  "",
                                                                                color:
                                                                                  "red",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-circle "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "threeHrNotice"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            item.type === "RXMT"
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                left: "",
                                                                                "x-small":
                                                                                  "",
                                                                                color:
                                                                                  "red",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-circle "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "retransmitted"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            item.type === "REPT"
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                left: "",
                                                                                "x-small":
                                                                                  "",
                                                                                color:
                                                                                  "red",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-circle "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "repeated"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            item.priority ===
                                                            "EMER"
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                left: "",
                                                                                "x-small":
                                                                                  "",
                                                                                color:
                                                                                  "red",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-circle "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "digin"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            item.type === "DMAR"
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                left: "",
                                                                                "x-small":
                                                                                  "",
                                                                                color:
                                                                                  "red",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-circle "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "Destroyed"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            item.type ===
                                                              "UPDT" &&
                                                            _vm.getCenterName(
                                                              item.center_id
                                                            ) === "JULIE"
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                left: "",
                                                                                "x-small":
                                                                                  "",
                                                                                color:
                                                                                  "red",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-circle "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "extended"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            item.type === "2NDR"
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                left: "",
                                                                                "x-small":
                                                                                  "",
                                                                                color:
                                                                                  "red",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-circle "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "secondNotice"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "item.expiration_date",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "nowrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.center_name ===
                                                                        "OHIO811"
                                                                        ? "N/A"
                                                                        : _vm.formatTicketDate(
                                                                            item.expiration_date
                                                                          )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "item.street_address",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "nowrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.street_address
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "item.user_id",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "nowrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getUserName(
                                                                      item.user_id
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "item.place",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "nowrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.place
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "item.county",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "nowrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.county
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "item.center_id",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "nowrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getCenterName(
                                                                        item.center_id
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: _vm.selected,
                                                    callback: function ($$v) {
                                                      _vm.selected = $$v
                                                    },
                                                    expression: "selected",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        this.$vuetify.breakpoint.smAndDown
                                          ? _c(
                                              "v-col",
                                              { staticClass: "pb-14" },
                                              [
                                                _c("v-data-iterator", {
                                                  attrs: {
                                                    id: "ticket-cards",
                                                    items: _vm.tickets,
                                                    "item-key": "ticket_id",
                                                    "fixed-header": "",
                                                    "show-select": "",
                                                    "loading-text":
                                                      "Loading items...",
                                                    "no-data-text": "",
                                                    page: _vm.options.page,
                                                    loading: _vm.loading,
                                                    options: _vm.options,
                                                    "items-per-page":
                                                      _vm.options.itemsPerPage,
                                                    "disable-pagination": "",
                                                    "hide-default-footer": "",
                                                  },
                                                  on: {
                                                    "update:page": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.options,
                                                        "page",
                                                        $event
                                                      )
                                                    },
                                                    "update:options": function (
                                                      $event
                                                    ) {
                                                      _vm.options = $event
                                                    },
                                                    "toggle-select-all":
                                                      _vm.mobileSelectAll,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "header",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "TicketsMobileDataToolbar",
                                                              {
                                                                attrs: {
                                                                  "display-toolbar":
                                                                    !!_vm
                                                                      .tickets
                                                                      .length,
                                                                  "selected-tickets":
                                                                    _vm.selected,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "selectCheckbox",
                                                                        fn: function () {
                                                                          return [
                                                                            _c(
                                                                              "v-checkbox",
                                                                              {
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm.mobileSelectAll,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.mobileSelectAll =
                                                                                          $$v
                                                                                      },
                                                                                    expression:
                                                                                      "mobileSelectAll",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    380368664
                                                                  ),
                                                              }
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                      {
                                                        key: "default",
                                                        fn: function (props) {
                                                          return _vm._l(
                                                            props.items,
                                                            function (item) {
                                                              return _c(
                                                                "TicketsMobileCard",
                                                                {
                                                                  key: item.ticket_id,
                                                                  staticClass:
                                                                    "px-4",
                                                                  attrs: {
                                                                    item: item,
                                                                    "is-selected":
                                                                      !!_vm.selected.find(
                                                                        function (
                                                                          ticket
                                                                        ) {
                                                                          return (
                                                                            ticket.ticket_id ===
                                                                            item.ticket_id
                                                                          )
                                                                        }
                                                                      ),
                                                                    "supports-responses":
                                                                      _vm.supportsResponses(
                                                                        item.center_id
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    showStatusDialog:
                                                                      _vm.showStatusDialog,
                                                                    onTicketDetails:
                                                                      _vm.onTicketDetails,
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "selectCheckbox",
                                                                          fn: function () {
                                                                            return [
                                                                              _c(
                                                                                "v-checkbox",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      value:
                                                                                        item,
                                                                                      multiple: true,
                                                                                      "value-comparator":
                                                                                        _vm.setMobileCheckBoxState,
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm.selected,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.selected =
                                                                                            $$v
                                                                                        },
                                                                                      expression:
                                                                                        "selected",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          },
                                                                          proxy: true,
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                }
                                                              )
                                                            }
                                                          )
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2822252788
                                                  ),
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-bottom-navigation",
                    {
                      staticClass: "px-2 py-4",
                      attrs: { fixed: "", horizontal: "", height: "80" },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: { justify: "space-between", "no-gutters": "" },
                        },
                        [
                          _vm.checkPermission("associateTicket")
                            ? _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "mr-2 text-none font-weight-regular grey--text rounded-lg elevation-0",
                                  attrs: {
                                    width: "50%",
                                    depressed: "",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit(
                                        "openAssociateTicketDialog"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "white--text text-none text-subtitle-1",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("associateTicket")))]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.checkPermission("createTickets")
                            ? _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "text-none font-weight-regular grey--text rounded-lg elevation-0",
                                  attrs: {
                                    width: "50%",
                                    depressed: "",
                                    color: "primary",
                                  },
                                  on: { click: _vm.createTicket },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "white--text text-none text-subtitle-1",
                                    },
                                    [
                                      _c("v-icon", [_vm._v("mdi-plus")]),
                                      _vm._v(_vm._s(_vm.$t("createTicket"))),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.responsesDialog
        ? _c(
            "v-layout",
            { attrs: { row: "", "justify-center": "" } },
            [
              _c(
                "v-dialog",
                {
                  attrs: { fullscreen: "", "hide-overlay": "" },
                  model: {
                    value: _vm.responsesDialog,
                    callback: function ($$v) {
                      _vm.responsesDialog = $$v
                    },
                    expression: "responsesDialog",
                  },
                },
                [
                  _c("MembersPanel", {
                    attrs: {
                      ticket: _vm.selectedTicket,
                      "show-responses": _vm.showResponses,
                    },
                    on: { hideTicketResponses: _vm.hideTicketResponses },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.responsesPanel
        ? _c("v-layout", { attrs: { row: "", "justify-end": "" } }, [
            _c(
              "div",
              { attrs: { id: "responsePanel" } },
              [
                _c("MembersPanel", {
                  attrs: {
                    ticket: _vm.selectedTicket,
                    "show-responses": _vm.showResponses,
                  },
                  on: { hideTicketResponses: _vm.hideTicketResponses },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }